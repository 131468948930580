import '../../../styles/giftCard.scss';
import React, {lazy, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import NoBrand from '@/assets/images/gift_cards/no_brand.png';
import WalletCard from '@/assets/images/gift_cards/wallet_card.png';
// import GiftCardHowItWorks from "@/components/Elements/GiftCardHowItWorks";
import storage from "@/utils/storage";
import {checkBoxApplyDiscount, getCardValueDiscount, giftPurchase} from "@/features/giftcard/api/redeemRewardsCard";
const GiftCardHeader = lazy(() => import('@/components/Elements/GiftCardHeader'));
const RedeemTermsCondition = lazy(() => import('@/components/Elements/RedeemTermsCondition'));

export const RedeemProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, images, sku, name, opigo_discount, min_price, max_price, logo } = location.state?.item || {};
    const minPrice = Number(min_price) ? Number(min_price).toFixed(0) : '0';
    const maxPrice = Number(max_price) ? Number(max_price).toFixed(0) : '0';
    const { balance } = location.state?.walletPrice || '';
    // const [email, setEmail] = useState('');
    const [isNumber, setIsNumber] = useState('');
    // const [isValidEmail, setIsValidEmail] = useState(true);
    const [cardValue, setCardValue] = useState('');
    const [isDiscountAmount, setIsDiscountAmount] = useState<any>('');
    const [isCheckBoxData, setIsCheckBoxData] = useState<any>();
    const [isValidValue, setIsValidValue] = useState(true);
    const [isDenomination, setIsDenomination] = useState<any>('');
    const [isValidNumber, setIsValidNumber] = useState(true);
    // const [isHowToUseModalOpen, setHowToUseModalOpen] = useState(false);
    const [isTermsModalOpen, setTermsModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isAPICalling, setIsAPICalling] = useState(false);
    const [isAPILoading, setIsAPILoading] = useState(false);
    const SecondDefaultImage = NoBrand;

    // const howItWorks = [
    //     { text: 'Open the app or website of the brand' },
    //     { text: 'Select gift cards section' },
    //     { text: 'Add gift card and enjoy the reward' },
    // ];

    const fetchAPIData = () => {
        const token = storage.getToken();
        if(token && token !== ''){
            storage.setToken(token);
        }
        if(!token && !storage.getToken()){
            return navigate('/')
        }
    };

    useEffect(() => {
        fetchAPIData();
    }, [navigate]);

    const handleCheckboxChange = async () => {
        setIsChecked(!isChecked);
        if (!isChecked){
            setIsAPICalling(true);
            const token = storage.getToken();
            const responseData = await checkBoxApplyDiscount({
                "product_id": id || '',
                "amount": cardValue || '',
                "discount_amount": isDiscountAmount || ''
            }, token!, id);
            setTimeout(() => {
                setIsAPICalling(false);
            }, 500);
            setIsCheckBoxData(responseData);
        }
    };

    // const validateEmail = (email:any) => {
    //     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return re.test(email);
    // };
    //
    // const handleEmailChange = (e: any) => {
    //     const newEmail = e.target.value;
    //     setEmail(newEmail);
    //     setIsValidEmail(validateEmail(newEmail));
    // };

    // const validatePhoneNumberWithCountryCode = (number: any) => {
    // Matches all country code
    //     const phonePattern = /^[1-9]\d{9,14}$/;
    //     return phonePattern.test(number);
    // };

    const validateIndianPhoneNumber = (number: string): boolean => {
        // Matches Indian phone numbers with exactly 10 digits starting with 6-9
        const phonePattern = /^[6-9]\d{9}$/;
        return phonePattern.test(number);
    };

    // Handle input changes and validation
    const handleNumberChange = (e: any) => {
        let number = e.target.value;

        number = number.replace(/\D/g, '');

        // Restrict the input to 10 digits
        if (number.length > 10) {
            number = number.slice(0, 10);
        }

        setIsNumber(number);
        setIsValidNumber(validateIndianPhoneNumber(number));
    };

    const handleCardValueChange = async (e: any) => {
        const newValue = e.target.value;
        setCardValue(newValue);
        if (newValue.length === 0){
            setIsChecked(false);
            setIsCheckBoxData('');
        }
        try {
            //@ts-ignore
            window.isRedeem = true;
            const res = await getCardValueDiscount(id, newValue);
            if (res && res.discount_amount !== undefined){
                setIsValidValue(true);
                setIsDiscountAmount(res.discount_amount);
                const token = storage.getToken();
                const responseData = await checkBoxApplyDiscount({
                    "product_id": id || '',
                    "amount": newValue || '',
                    "discount_amount": res.discount_amount || ''
                }, token!, id);
                setIsChecked(true);
                setIsCheckBoxData(responseData);
            } else if (res.response.data.error) {
                setIsDenomination(res.response.data.error);
                setIsValidValue(false);
            } else {
                setIsDiscountAmount(0);
                setIsValidValue(false);
            }
        } catch (e) {
            setIsValidValue(true)
            setIsDenomination('');
            console.log('API error', e);
        }
    };

    const payToProceed = async () => {
        setIsAPILoading(true);
        const localToken = storage.getToken();
        const data = {
            product_id: id,
            amount:  isChecked ? isCheckBoxData?.final_amount : cardValue || '',
            original_amount: isCheckBoxData?.original_amount || '',
            wallet_amount: isDiscountAmount || '',
            whatsapp_number: isNumber || '',
            device_os: "web",
            source: "web",
        }
        const responseData = await giftPurchase(data, localToken!)
        const redirectUrl = responseData?.payments?.data?.instrumentResponse?.redirectInfo?.url
        if (redirectUrl != undefined) {
            window.location.href = redirectUrl
        }
        setTimeout(() => {
            setIsAPILoading(false);
        }, 500);
    };

    return (
        <div className="main-giftCard-container">
            <div className="wallet-container">
                {/* Header */}
                <GiftCardHeader name={'Complete your purchase'} isRightView={false} />
                <div className="product-main-container">

                    {
                        logo ? (
                            <img
                                className="brand-logo"
                                alt="brandImg"
                                src={logo}
                                onError={(e:any) => {
                                    e.target.onerror = null;
                                    e.target.src = SecondDefaultImage;
                                }}
                            />
                        ) : (
                            <img
                                src={NoBrand}
                                alt="brandImg"
                                className="brand-logo"
                            />
                        )
                    }

                    <div style={{marginLeft: 14}}>

                        <div className="brand-name"> {name?.length > 30 ? name?.substring(0, 30) + '...' : name}</div>
                        <h2 style={{color: '#5B5B5B'}}>{`Save ${opigo_discount ? opigo_discount : 'X%'} using wallet balance`}</h2>
                        <h2>{`Enter values between ₹${minPrice} & ₹${maxPrice}`}</h2>
                    </div>
                </div>

                {/* Gift Card Value */}
                <div className="card-value-container">
                    <h4>Gift Card Value</h4>
                    <div className={'card-input-wrapper'}>
                        <input
                            type="number"
                            value={cardValue}
                            onChange={handleCardValueChange}
                            placeholder={'Enter in ₹'}
                            className={'card-input'}
                            style={{borderColor: !isValidValue ? '#EF4C49': "#C2CDD1", borderWidth: !isValidValue ?  1.5 : 1 }}
                        />
                    </div>
                    {!isValidValue && <p className="card-error-message">{isDenomination}</p>}
                </div>


                {/* Receive coupon on */}
                <div className="email-input-container">
                    <h4>Receive coupon on</h4>
                    <div className={'input-wrapper'}>
                        <input
                            type="tel"
                            value={isNumber}
                            onChange={handleNumberChange}
                            placeholder="Enter 10-digit WhatsApp number"
                            className="email-input"
                            style={{borderColor: !isValidNumber ? '#EF4C49': "#C2CDD1", borderWidth: !isValidNumber ?  1.5 : 1 }}
                        />
                    </div>
                    {!isValidNumber && <p className="error-message">Please enter valid number</p>}
                </div>


                {/* Use Balance Section */}
                <div className="balance-section-container">
                    <div className="balance-option">
                        <div className="balance-info">
                            <img
                                src={WalletCard}
                                alt="Wallet"
                                className="wallet-icon"
                            />
                            <div className="balance-details">
                                <div className="balance-title">
                                    {balance > 0 ? (
                                        <>
                                            <span>Get ₹{isDiscountAmount ?  isDiscountAmount.toFixed(2) : 0} discount</span>
                                            <span className="balance-off-text">₹{isDiscountAmount ?  isDiscountAmount.toFixed(2) : 0} will be deducted from wallet</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>Get ₹0 discount</span>
                                            <span className="no-balance-text">No Wallet balance available</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        {balance > 0 ? (
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                className="balance-checkbox"
                            />
                        ) : null}
                    </div>

                    {/* Payment Button */}
                    <button className="payment-button"
                            onClick={payToProceed}
                            disabled={!cardValue || !isValidValue || !isNumber || !isValidNumber}
                            style={{ opacity: (!cardValue || !isValidValue || !isNumber || !isValidNumber) ? 0.6 : 1 }}>
                        {isAPILoading ? (
                            <span>Loading...</span>
                        ) : (
                            <>
                                {isChecked ? (
                                    <>
                                        {isAPICalling ? (
                                            <span>Loading...</span>
                                        ) : (
                                            <>
                                                {/*<span style={{ textDecoration: 'line-through', marginRight: '8px' }}>Get ₹{isCheckBoxData?.original_amount || '0'}</span>*/}
                                                {/*₹{isCheckBoxData?.final_amount}*/}
                                                <span style={{ marginRight: '8px' }}>{`Get it for just ₹${isCheckBoxData?.final_amount || '0'}`}</span>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <span style={{ alignItems:'center' }}>{cardValue ? `Get it for just ₹${cardValue}` : 'Get it for just ₹0'}</span>
                                )}
                            </>
                        )}
                    </button>
                </div>

                {/* How to use and Terms and Conditions */}
                <div className="info-section">
                    {/*<div className="info-item" onClick={() => setHowToUseModalOpen(true)}>*/}
                    {/*    <h6>How to use</h6>*/}
                    {/*    <h6 className="right-arrow">›</h6>*/}
                    {/*</div>*/}
                    <div className="info-item" onClick={() => setTermsModalOpen(true)}>
                        <h6>Terms and Conditions</h6>
                        <h6 className="right-arrow">›</h6>
                    </div>
                    {/* How to Use Modal */}
                    {/*{isHowToUseModalOpen && (*/}
                    {/*    <div className="modal-overlay" onClick={() => setHowToUseModalOpen(false)}>*/}
                    {/*        <div className="modal-content">*/}
                    {/*            <div className="modal-wrapper" onClick={() => setHowToUseModalOpen(false)}/>*/}
                    {/*            <h4>How to use</h4>*/}
                    {/*            <GiftCardHowItWorks howItWorks={howItWorks}  />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    {/* Terms and Conditions Modal */}
                    {isTermsModalOpen && (
                        <div className="modal-overlay" onClick={() => setTermsModalOpen(false)}>
                            <div className="modal-content">
                                <div className="modal-wrapper" onClick={() => setTermsModalOpen(false)} />
                                <h4>Terms and Conditions for OpiGo Wallet Rewards</h4>
                                <RedeemTermsCondition/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
