import { AppRoutes } from './routes';
import { AppProvider } from './providers/app';
import ReactGA from "react-ga4";

// Initialize gtag with your Measurement ID
ReactGA.initialize('AW-16786036394'); // Use only the Measurement ID here


function App() {
  return (
    <AppProvider >
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
