import {PurchaseStep} from "@/features/decks/components/PurchaseStep";
import React from "react";

const PlanSelection = ({ deck, selectedPlan, onPressSelectPlan, isMobile }: any) => {

  if (isMobile){
    return <div className="d-flex plan-selection-container">
      {deck?.channel_packages?.map((packageItem:any) => (
        <div
          key={packageItem.id}
          className={`plan-type-box ${selectedPlan === packageItem.id ? 'selectedPlan' : ''}`}
          onClick={() => onPressSelectPlan(packageItem)}
        >

          <div className="plan-details">
            <div className="plan-title-container">
              <h3 className="plan-title">{packageItem?.title ? `₹${packageItem?.title}` : '---'}</h3>
            </div>

            {packageItem?.description && (
              <p className="plan-description">{packageItem.description || '---'}</p>
            )}
          </div>
          <div className="radio-circle"  style={{
            border: selectedPlan === packageItem.id ? "1px solid rgb(69,166,75)" : "1px solid #777777",
          }}>
            {selectedPlan === packageItem.id && <div className="selected-rb"/>}
          </div>
        </div>
      ))}
    </div>
  }
    return (
        <PurchaseStep active={true} completed={selectedPlan} id={1} title={'Choose Your Plan Type'}>
            <div className="d-flex plan-selection-container">
                {deck?.channel_packages?.map((packageItem:any) => (
                    <div
                        key={packageItem.id}
                        className={`plan-type-box ${selectedPlan === packageItem.id ? 'selectedPlan' : ''}`}
                        onClick={() => onPressSelectPlan(packageItem)}
                    >

                        <div className="plan-details">
                            <div className="plan-title-container">
                                <h3 className="plan-title">{packageItem?.title ? `₹${packageItem?.title}` : '---'}</h3>
                            </div>

                            {packageItem?.description && (
                                <p className="plan-description">{packageItem.description || '---'}</p>
                            )}
                        </div>
                        <div className="radio-circle"  style={{
                            border: selectedPlan === packageItem.id ? "1px solid rgb(69,166,75)" : "1px solid #777777",
                        }}>
                            {selectedPlan === packageItem.id && <div className="selected-rb"/>}
                        </div>
                    </div>
                ))}
            </div>
        </PurchaseStep>
    );
};

export default PlanSelection;
