import {useEffect, useRef, useState, useMemo, lazy, Suspense} from 'react';
import '../../../styles/deck.scss';
import DeckFooter from '@/components/Layout/DeckFooter';
import { useDecks } from '@/features/decks/api/getDecks';
import { getUtmMedium } from '@/utils/utility';
import React from 'react';
import NewExploreDeckCard from "@/components/Elements/NewExploreDeckCard";

const Subscribe = lazy(() => import('@/features/misc/pages/common/Subscribe'));
const HowItWorks = lazy(() => import('@/features/misc/pages/common/HowItWorks'));
const Expert = lazy(() => import('@/features/misc/pages/common/Expert'));
const Convinced = lazy(() => import('@/features/misc/pages/common/Convinced'));
const FAQDeck = lazy(() => import('@/features/misc/pages/common/FAQDeck'));
const MainHeader = lazy(() => import('@/features/misc/pages/common/MainHeader'));
const Featured = lazy(() => import('@/features/misc/pages/common/Featured'));
const FeatureSection = lazy(() => import('@/features/misc/pages/common/FeatureSection'));

const ChooseDeck = () => {
    const decksQuery = useDecks({ config: {} });
    const deckRef = useRef<HTMLDivElement | null>(null);
    const sliderRef = useRef<any>(null);
    // const [selectedTime, setSelectedTime] = useState('1 Month');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [decksData, setDecksData] = useState<any[]>([]);
    // const [filters, setFilters] = useState<any[]>([]);
    const [lowestPackage, setLowestPackage] = useState('');

    const utmData = useMemo(() => getUtmMedium(), []);
    const deviceType = sliderRef.current?.state.deviceType === 'mobile' ? 1 : 3;

    const handleNextSlide = () => {
        if (currentSlide < decksData.length - deviceType) {
            const nextSlide = currentSlide + 1;
            setCurrentSlide(nextSlide);
            sliderRef.current?.goToSlide(nextSlide);
        }
    };

    const handlePreviousSlide = () => {
        if (currentSlide > 0) {
            const previousSlide = currentSlide - 1;
            setCurrentSlide(previousSlide);
            sliderRef.current?.goToSlide(previousSlide);

        }
    };

    useEffect(() => {
        if (decksQuery?.data?.length) {
            const response = decksQuery?.data;
            setDecksData(decksQuery?.data);
            setLowestPackage(response.lowestPackage)
        }
    }, [decksQuery.data]);

    // useEffect(() => {
    //     if (decksQuery.data?.length) {
    //         const { selectedTime, filters } = manageFilters(decksQuery.data);
    //         setSelectedTime(selectedTime);
    //         setFilters(filters);
    //     }
    // }, [decksQuery.data]);

    return (
        <div className='deck-body'>
            <Suspense fallback={<div>.</div>}>
            <MainHeader deckRef={deckRef} />
            <Featured />
            <Convinced sliderRef={sliderRef} handlePreviousSlide={handlePreviousSlide} handleNextSlide={handleNextSlide} />

            {/* Explore Decks */}
            <section className='explore-decks' ref={deckRef}>
                <h2 className='text-center text-black mb-3 mb-lg-4 deck-common-sub-heading'>Choose a Plan</h2>
                <p className='text-black deck-common-body-md text-center text-capitalize'>Subscribe to a Plan and start building your portfolio</p>

                {/*{filters.length > 0 && (*/}
                {/*  <DeckTimeFilter data={filters} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />*/}
                {/*)}*/}

                <div className='container d-flex flex-column align-items-center justify-content-center'>
                  {decksQuery.isLoading ? (
                    <p className='fw-medium mt-3'>Loading</p>
                  ) : (
                    decksData.map((deck, index) => (
                      <NewExploreDeckCard
                          key={deck.id}
                          deck={deck}
                          // selectedTime={selectedTime}
                          utmData={utmData}
                      />
                    ))
                  )}
                </div>
              </section>

            <FeatureSection />
            <Expert />

            <section className='works container'>
                <h2 className='text-center deck-common-sub-heading'>How it works</h2>
                <HowItWorks />
            </section>

            <Subscribe />
            <FAQDeck />
            <DeckFooter />

            </Suspense>

            {decksData?.length > 0 && (
                <div className='d-flex align-content-center justify-content-between d-md-none position-fixed bottom-0 w-100 offer-section overflow-hidden'>
                    <div>
                        <h3 className='title-offer'>Get Flat 10% OFF!</h3>
                        <p className='desc-offer'>
                            <span style={{ fontSize: '0.875rem' }}>
                                Use code SUPER10
                            </span>
                        </p>
                    </div>

                    <button onClick={() => deckRef.current?.scrollIntoView({ behavior: 'smooth' })}>
                        Explore Plans
                    </button>
                </div>
            )}
        </div>
    );
};

export default React.memo(ChooseDeck);
