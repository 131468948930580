import '../../../styles/giftCard.scss';
import React, { lazy, useEffect, useState } from 'react';
import WalletCard from '@/assets/images/gift_cards/wallet_card.png';
import NoBrand from '@/assets/images/gift_cards/no_brand.png';
import RedeemRewards from '@/assets/images/app_stores/redeem_rewards.png';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    getRewards,
    getWalletBalance,
    giftPurchaseFlag,
} from '@/features/giftcard/api/redeemRewardsCard';
import storage from '@/utils/storage';
const GiftCardHeader = lazy(() => import('@/components/Elements/GiftCardHeader'));
const GiftCardHowItWorks = lazy(() => import('@/components/Elements/GiftCardHowItWorks'));

export const RedeemRewardsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [apiData, setApiData] = useState<null | any>([]);
    const [walletPrice, setWalletPrice] = useState<null | any>({});
    const [isAPICalling, setIsAPICalling] = useState<boolean>(true);
    const [featureFlag, setFeatureFlag] = useState<boolean>(false);
    const SecondDefaultImage = NoBrand;

    const fetchAPIData = async () => {
        setIsAPICalling(true);
        const getQueryParam = (param: any) => {
            const searchParams = new URLSearchParams(location.search);
            return searchParams.get(param);
        };

        const token = getQueryParam('token');

        if (token && token !== '') {
            storage.setToken(token);
        }

        if (!token && !storage.getToken()) {
            return navigate('/');
        }
            try {
                const res = await getRewards();
                const walletResponse = await getWalletBalance();
                setApiData(res);
                setWalletPrice(walletResponse);
                setIsAPICalling(false);
            } catch (error) {
                setIsAPICalling(false);
                console.error('Error API:', error);
            }
    };

    const checkParticularUser = async () => {
        try {
            setIsAPICalling(true);
            const res = await giftPurchaseFlag();
            console.log('Check Feature Flag');
            console.log(JSON.stringify(res))
            setFeatureFlag(res?.is_allowed);
            setIsAPICalling(false);
        } catch (error) {
            setIsAPICalling(false);
            console.error('Error API:', error);
        }
    }

    useEffect(() => {
        fetchAPIData();
        checkParticularUser();
    }, [navigate]);

    const howItWorks = [
        { text: 'Choose a gift card to buy from popular brands' },
        { text: 'Redeem your wallet balance for instant discounts on gift cards ' },
        { text: 'Complete the payment and receive your gift card on email' },
    ];

    const handleClick = (item: any) => {
        navigate('/redeem-rewards/product', { state: { item, walletPrice } });
    };

    if(!isAPICalling && !featureFlag){
        return  (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <img src={RedeemRewards} alt="Redeem Now"  width='350px'/>
          </div>
        )
    }
    // if (isValidToken.data?.attributes?.full_phone_number === '919156010865') {
        return (
            <div className="main-giftCard-container">
                <div className="wallet-container">
                    <GiftCardHeader isRightView={true} />

                    {isAPICalling ? (
                        <div className="d-flex flex-column align-items-center justify-content-center fw-bold mt-3">
                            Loading...
                        </div>
                    ) : (
                        <>
                            <div className="wallet-balance-card">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                    }}>
                                    <div className="wallet-balance-info">
                                        <img
                                            src={WalletCard}
                                            alt="wallet"
                                            className="wallet-icon"
                                        />
                                        <div>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    color: '#545050',
                                                    fontWeight: 'normal',
                                                }}>
                                                Available Discount
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 16,
                                                    color: 'black',
                                                    fontWeight: '500',
                                                    paddingLeft: 5,
                                                }}>
                                                {walletPrice?.currency_code || ''}{' '}
                                                {walletPrice?.balance || ''}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="redeem-gift-cards">
                                <p className="gift-card-text">Get Instant Discounts</p>
                                <div className="gift-card-grid">
                                    {apiData?.map((item: any, index: number) => (
                                        <div className="gift-card" key={index}>
                                            {item.logo ? (
                                                <img
                                                    src={item.logo}
                                                    onClick={() => handleClick(item)}
                                                    onError={(e: any) => {
                                                        e.target.onerror = null;
                                                        e.target.src = SecondDefaultImage;
                                                    }}
                                                />
                                            ) : (
                                                <img src={NoBrand} onClick={() => handleClick(item)} />
                                            )}
                                            <p className="company-name">
                                                {item?.name}
                                            </p>
                                            <h6 className="discount-text">
                                                {item?.opigo_discount || ''}<h6 className="discount-text-Off">
                                                {'OFF' || ''}
                                            </h6>
                                            </h6>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    // } else {
    //     return (
    //         <div
    //             style={{
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 height: '100vh',
    //             }}>
    //             <img src={RedeemRewards} alt="Redeem Now" width='350px'/>
    //         </div>
    //     );
    // }
};
