import Ashutosh from '@/assets/images/experts/ashutosh bhardwaj.png'
import Mayank from '@/assets/images/experts/mayank chandel.png'
import Ravi from '@/assets/images/experts/ravi bhatt.png'
import Varun from '@/assets/images/experts/varun patel.jpg'
import TrendSvg from '@/assets/images/icons/trend.svg'
import DateSvg from '@/assets/images/icons/date.svg'

import user1 from '@/assets/images/testimonials/user1.webp'
import user2 from '@/assets/images/testimonials/user2.webp'
import user3 from '@/assets/images/testimonials/user3.webp'
import user4 from '@/assets/images/testimonials/user4.webp'
import user5 from '@/assets/images/testimonials/user5.webp'

import messageImg from '@/assets/images/common/messageImg.png'
import BlueTick from '@/assets/images/common/BlueTick.png'
import PlayZone from '@/assets/images/common/PlayZone.png'
import ComunityPols from '@/assets/images/common/ComunityPols.png'
import TrackIdeas from '@/assets/images/common/TrackIdeas.png'
import MoreFeatures from '@/assets/images/common/MoreFeatures.png'


import Expert1 from '@/assets/images/deck/experts/ravi-bhatt.png';
import Expert2 from '@/assets/images/experts/varun patel.jpg'
import Expert4 from '@/assets/images/deck/experts/khushal.png';
import Expert5 from '@/assets/images/deck/experts/mayank.png';
import Expert6 from '@/assets/images/deck/experts/ashutosh.png';
import Expert7 from '@/assets/images/deck/experts/yash.png';
import Expert8 from '@/assets/images/deck/experts/prateek.jpg';
import Expert9 from '@/assets/images/deck/experts/vipin.jpg';
import Expert10 from '@/assets/images/deck/experts/prabhat.png';

import MAZDOCK from '@/assets/images/deck/stocks/mazdock.jpg';
import Aaron from '@/assets/images/deck/stocks/aaron.webp';
import Graphite from '@/assets/images/deck/stocks/graphite.webp';
import Fortis from '@/assets/images/deck/stocks/fortis.webp';
import Exicom from '@/assets/images/deck/stocks/exicom.webp';
import BajajSteel from '@/assets/images/deck/stocks/bajaj.webp';
import Samvardhana from '@/assets/images/deck/stocks/samvardhan.webp';
import Worthington from '@/assets/images/deck/stocks/worthington.webp';
import McLeodRussel from '@/assets/images/deck/stocks/mcleodrussel.webp';
import Interarch from '@/assets/images/deck/stocks/interarch.webp';
import Iiflsecurities from '@/assets/images/deck/stocks/Iiflsecurities.webp';
import Nationalum from '@/assets/images/deck/stocks/nationalum.webp';
import Railvikas from '@/assets/images/deck/stocks/railvikas.webp';
import Stovekraft from '@/assets/images/deck/stocks/stovekraft.webp';
import Tilaknagar from '@/assets/images/deck/stocks/tilaknagar.webp';
import Marksans from '@/assets/images/deck/stocks/Marksans.webp';
import Precot from '@/assets/images/deck/stocks/precot.webp';
import FORCEMOT from '@/assets/images/deck/stocks/forcemote.webp';
import TI from '@/assets/images/deck/stocks/ti.webp';
import MCLEODRUSS from '@/assets/images/deck/stocks/mcleo.webp';
import SAREGAMA from '@/assets/images/deck/stocks/saregama.webp';

export const homeDecksData = [
    {
        image: Ashutosh,
        name: 'Ashutosh Bhardwaj',
        experience: 'Exp: 10+ Years',
        info: '17.3% Gains in 15 Days',
        data: [
            {
                title: 'FORCEMOT',
                info: 'Symbol',
                image: FORCEMOT
            },
            {
                title: 'Growth Titans',
                info: 'Deck',
                image: TrendSvg
            },
            {
                title: '24-10-2024',
                info: 'Entry',
                image: DateSvg
            },
            {
                title: '08-11-24',
                info: 'Exit',
                image: DateSvg
            }
        ]
    },
    {
        image: Varun,
        name: "Varun Patel",
        experience: "Exp: 15+ Years",
        info: '8.0% Gains in 2 Days',
        data: [
            {
                title: 'TI',
                info: 'Symbol',
                image: TI
            },
            {
                title: 'Trading Alpha',
                info: 'Deck',
                image: TrendSvg
            },
            {
                title: '05-11-24',
                info: 'Entry',
                image: DateSvg
            },
            {
                title: '07-11-24',
                info: 'Exit',
                image: DateSvg
            }
        ]
    },
    {
        image: Ashutosh,
        name: 'Ashutosh Bhardwaj',
        experience: 'Exp: 10+ Years',
        info: '39.8% Gains in 162 Days',
        data: [
            {
                title: 'MCLEODRUSS',
                info: 'Symbol',
                image: MCLEODRUSS
            },
            {
                title: 'Growth Titans',
                info: 'Deck',
                image: TrendSvg
            },
            {
                title: '20-06-2024',
                info: 'Entry',
                image: DateSvg
            },
            {
                title: '29-11-24',
                info: 'Exit',
                image: DateSvg
            }
        ]
    },
    {
        image: Mayank,
        name: "Mayank Singh Chandel",
        experience: "Exp: 8+ Years",
        info: '21.7% Gains in 91 Days',
        data: [
            {
                title: 'SAREGAMA',
                info: 'Symbol',
                image: SAREGAMA
            },
            {
                title: 'Growth Titans',
                info: 'Deck',
                image: TrendSvg
            },
            {
                title: '04-07-24',
                info: 'Entry',
                image: DateSvg
            },
            {
                title: '03-10-24',
                info: 'Exit',
                image: DateSvg
            }
        ]
    },
]

export const homeTestimonialData = [
    {
      image: user1,
      description: "After experiencing a loss in my previous investment journey, again I tried to give a try and found this OpiGo app where I started receiving Card Decks from SEBI registered experts and made my journey trust worthy with 6-11% avg returns.",
      userName: "Lohith Allamsetty",
      userStatus: "Managing Head, Cosmo"
    },
    {
      image: user2,
      description: "This is a novel and wonderful concept to take control of one's investment in stocks without relying on hearsay. It gives an opportunity to follow friends and experts in this field and make wise investment decision. Will like to see integration for direct investment through the app in near future.",
      userName: "Siddharth Parekh",
      userStatus: "Founder, Coffee Brew"
    },
    {
      image: user3,
      description: "A great app for beginners as well non finance people to learn and know about stocks and financial expertise. A great platform to grow your investment.",
      userName: "Vaibhav Shah",
      userStatus: "Managing Head, Cosmo"
    },
    {
      image: user4,
      description: "Seamless and easy to use app. The Cards and Polls feature is extremely helpful and effective to track stocks and make investment decisions. Now we can follow SEBI registered experts, get queries resolved and discuss investments - all in one place. All this in an accountable and reliable way.",
      userName: "Vismay Tejas Tolia",
      userStatus: "Product Designer, Cipla"
    },
    {
      image: user5,
      description: "Opigo is a perfect platform for beginners like me to have expert opinions on stocks, investment financial management services. It's more like social connect but in terms of trading or investment ideas. Have followed the leads of some people and have really benefitted from the same.",
      userName: "Jihan Parikh",
      userStatus: "Managing Head, Cosmo"
    },
]

export const homeFeaturesData = [
    {
      title: 'Stock Cards',
      description: 'Cards in OpiGo are like tweets on Twitter to Share Stock Ideas',
      image: messageImg,
      color: '#F86D58'
    },
    {
      title: 'Dynamic Scoring',
      description: 'Meant to differentiate the Ninjas from average investors',
      image: BlueTick,
      color: '#9CCBFB'
    },
    {
      title: 'PlayZone',
      description: 'Daily stock picking contests to practice & earn cash rewards',
      image: PlayZone,
      color: '#94DF93'
    },
    {
      title: 'Community Polls',
      description: 'Ask the community if you should Buy/Hold/Sell a stock',
      image: ComunityPols,
      color: '#FD942E'
    },
    {
      title: 'Track Ideas',
      description: 'Track 20k+ ideas, stocks & indices, in real-time',
      image: TrackIdeas,
      color: '#9780FF'
    },
    {
      title: 'More Features...',
      description: 'Social, research, forums, news & much more',
      image: MoreFeatures,
      color: '#FFA828'
    }
]

export const decksexpertsData = [
    {
        name: 'Ravi Bhatt',
        image: Expert1,
        exp: '10+'
    },
    {
        name: 'Varun Patel',
        image: Expert2,
        exp: '15+'
    },
    // {
    //     name: 'Alok Daiya',
    //     image: Expert3,
    //     exp: '21+'
    // },
    {
        name: 'Khushal Lakhani',
        image: Expert4,
        exp: '10+'
    },
    {
        id: 5,
        name: 'Mayank Chandel',
        image: Expert5,
        exp: '8+'
    },
    {
        id: 6,
        name: 'Ashutosh Bhardwaj',
        image: Expert6,
        exp: '10+'
    },
    {
        id: 7,
        name: 'Yash Birajdar',
        image: Expert7,
        exp: '7+'
    },
    {
        id: 8,
        name: 'Prateek Bhardwaj',
        image: Expert8,
        exp: '15+'
    },
    {
        id: 9,
        name: 'Vipin Dixena',
        image: Expert9,
        exp: '6+'
    },
    {
        id: 10,
        name: 'Prabhat Mittal',
        image: Expert10,
        exp: '18+'
    },
]

export const decksCardsData = [
    {
        image: Varun,
        name: 'Varun Patel',
        experience: 'Exp: 15+ Years',
        info: '34% Gains in 35 Days',
        entryOn: '6-Nov-24',
        exitOn: '6-Dec-24',
        stock: {
            title: 'Worthington Pump India Ltd',
            icon: Worthington
        }
    },
    {
        image: Mayank,
        name: "Mayank Singh Chandel",
        experience: "Exp: 8+ Years",
        info: '9.9% Gains in 12 Days',
        entryOn: '28-Nov-24',
        exitOn: '10-Dec-24',
        stock: {
            title: 'Aaron Industries',
            icon: Aaron,
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '39.8% Gains in 162 Days',
        entryOn: '20-Jun-24',
        exitOn: '29-Nov-24',
        stock: {
            title: 'McLeod Russel',
            icon: McLeodRussel
        }
    },
    {
        image: Varun,
        name: "Varun Patel",
        experience: "Exp: 15+ Years",
        info: '8.0% Gains in 2 Days',
        entryOn: '5-Nov-24',
        exitOn: '7-Nov-24',
        stock: {
            title: 'Tilaknagar Industries',
            icon: Tilaknagar
        }
    },
    {
        image: Ashutosh,
        name: 'Ashutosh Bhardwaj',
        experience: 'Exp: 10+ Years',
        info: '19.0% Gains in 47 Days',
        entryOn: '22-Sept-24',
        exitOn: '8-Nov-24',
        stock: {
            title: 'Precot Limited',
            icon: Precot
        }
    },
    {
        image: Mayank,
        name: 'Mayank Singh Chandel',
        experience: 'Exp: 8+ Years',
        info: '21.7% Gains in 91 Days',
        entryOn: '4-Jul-24',
        exitOn: '3-Oct-24',
        stock: {
            title: 'Saregama',
            icon: SAREGAMA
        }
    },
    {
        image: Ravi,
        name: 'Ravi Bhatt',
        experience: 'Exp: 10+ Years',
        info: '12.0% Gains in 18 Days',
        entryOn: '13-Sept-24',
        exitOn: '1-Oct-24',
        stock: {
            title: 'Samvardhana Motherson',
            icon: Samvardhana
        }
    },
    {
        image: Varun,
        name: "Varun Patel",
        experience: "Exp: 15+ Years",
        info: '10.1% Gains in 4 Days',
        entryOn: '11-Oct-24',
        exitOn: '15-Oct-24',
        stock: {
            title: 'Interarch Building Products',
            icon: Interarch
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '17.5% Gains in 55 Days',
        entryOn: '7-Aug-24',
        exitOn: '1-Oct-24',
        stock: {
            title: 'Graphite India',
            icon: Graphite
        }
    },
    {
        image: Mayank,
        name: "Mayank Singh Chandel",
        experience: "Exp: 8+ Years",
        info: '20.9% Gains in 16 Days',
        entryOn: '11-Sept-24',
        exitOn: '27-Sept-24',
        stock: {
            title: 'National Aluminium Company',
            icon: Nationalum
        }
    },
    {
        image: Varun,
        name: "Varun Patel",
        experience: "Exp: 15+ Years",
        info: '15.2% Gains in 21 Days',
        entryOn: '14-Aug-24',
        exitOn: '4-Sept-24',
        stock: {
            title: 'Marksans Pharma',
            icon: Marksans
        }
    },
    {
        image: Mayank,
        name: 'Mayank Singh Chandel',
        experience: 'Exp: 8+ Years',
        info: '19.6% Gains in 61 Days',
        entryOn: '3-Jul-24',
        exitOn: '2-Sept-24',
        stock: {
            title: 'Fortis Healthcare',
            icon: Fortis
        }
    },
    {
        image: Expert7,
        name: "Yash Birajdar",
        experience: "Exp: 7+ Years",
        info: '15,8% Gains in 1 Days',
        entryOn: '19-Aug-24',
        exitOn: '20-Aug-24',
        stock: {
            title: 'IIFL Securities',
            icon: Iiflsecurities
        }
    },
    {
        image: Mayank,
        name: 'Mayank Singh Chandel',
        experience: 'Exp: 8+ Years',
        info: '24.3% Gains in 19 Days',
        entryOn: '1-Aug-24',
        exitOn: '20-Aug-24',
        stock: {
            title: 'Stove Kraft',
            icon: Stovekraft
        }
    },
    {
        image: Ashutosh,
        name: "Ashutosh Bhardwaj",
        experience: "Exp: 10+ Years",
        info: '25.9% Gains in 72 Days',
        entryOn: '22-Jun-24',
        exitOn: '2-Aug-24',
        stock: {
            title: 'Bajaj Steel Industries',
            icon: BajajSteel
        }
    },
    {
        image: Expert7,
        name: "Yash Birajdar",
        experience: "Exp: 7+ Years",
        info: '74.0% Gains in 21 Days',
        entryOn: '13-Jun-24',
        exitOn: '4-Jul-24',
        stock: {
            title: 'MAZDOCK',
            icon: MAZDOCK
        }
    },
    {
        image: Expert7,
        name: "Yash Birajdar",
        experience: "Exp: 7+ Years",
        info: '50.9% Gains in 22 Days',
        entryOn: '18-Jun-24',
        exitOn: '10-Jul-24',
        stock: {
            title: 'Rail Vikas Nigam Ltd',
            icon: Railvikas
        }
    },
    {
        image: Expert7,
        name: 'Yash Birajdar',
        experience: 'Exp: 7+ Years',
        info: '45.4% Gains in 30 Days',
        entryOn: '10-Jun-24',
        exitOn: '10-Jul-24',
        stock: {
            title: 'EXICOM',
            icon: Exicom
        }
    },
]

