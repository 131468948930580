import React, { useEffect, useRef, useState, useMemo, useCallback, lazy, Suspense } from 'react';
import '../../../styles/deck.scss';
import ExploreDeckCard from '@/components/Elements/ExploreDeckCard';
import DeckFooter from '@/components/Layout/DeckFooter';
import { useDecks } from '@/features/decks/api/getDecks';
import { getUtmMedium } from '@/utils/utility';
import { decksCardsData } from '@/utils/constant';
import { useNavigate } from 'react-router-dom';

const Expert = lazy(() => import('@/features/misc/pages/common/Expert'));
const Subscribe = lazy(() => import('@/features/misc/pages/common/Subscribe'));
const Convinced = lazy(() => import('@/features/misc/pages/common/Convinced'));
const FAQDeck = lazy(() => import('@/features/misc/pages/common/FAQDeck'));
const MainHeader = lazy(() => import('@/features/misc/pages/common/MainHeader'));
const Featured = lazy(() => import('@/features/misc/pages/common/Featured'));
const FeatureSection = lazy(() => import('@/features/misc/pages/common/FeatureSection'));
const HowItWorks = lazy(() => import('@/features/misc/pages/common/HowItWorks'));

const TradingAlphas = () => {
    const navigate = useNavigate();
    const decksQuery = useDecks({ config: {} });
    const deckRef = useRef(null);
    const sliderRef = useRef<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [decksData, setDecksData] = useState<any[]>([]);
    const [lowestPackage, setLowestPackage] = useState(Infinity);
    const utmData = useMemo(() => getUtmMedium(), []);
    const device = useMemo(() => sliderRef.current?.state.deviceType === 'mobile' ? 1 : 3, [sliderRef.current?.state.deviceType]);

    const handleNextSlide = useCallback(() => {
        if (currentSlide < decksCardsData.length - device) {
            const nextSlide = currentSlide + 1;
            setCurrentSlide(nextSlide);
            sliderRef.current?.goToSlide(nextSlide);
        }
    }, [currentSlide, device]);

    const handlePreviousSlide = useCallback(() => {
        if (currentSlide > 0) {
            const prevSlide = currentSlide - 1;
            setCurrentSlide(prevSlide);
            sliderRef.current?.goToSlide(prevSlide);
        }
    }, [currentSlide]);

    const filterDataBasedOnTime = useCallback(() => {
        if (!decksQuery?.data?.length) return;

        const filteredData : any = [];
        let tempLowestPackage = Infinity;

        decksQuery.data.forEach((deck: any) => {
            if (deck.title.includes('Trading Alpha')) {
                deck.channel_packages?.forEach((item: any) => {
                    if (!item.sTimebase) {
                        if (item.price < tempLowestPackage) {
                            tempLowestPackage = item.price;
                        }
                        filteredData.push(deck);
                    }
                });
            }
        });

        setDecksData(filteredData);
        setLowestPackage(tempLowestPackage);
    }, [decksQuery?.data]);

    useEffect(() => {
        if (!decksQuery.isLoading && decksQuery.data?.length > 0) {
            filterDataBasedOnTime();
        }
    }, [decksQuery.isLoading, filterDataBasedOnTime]);

    const navigateToPayment = useCallback(() => {
        if (decksData.length > 0) {
            navigate(`/decks/purchase/${decksData[0].id}/${decksData[0].channel_packages[0].id}?${utmData}`);
        }
    }, [decksData, navigate, utmData]);

    return (
        <div className='deck-body'>
            <Suspense fallback={<div>.</div>}>
                <MainHeader deckRef={deckRef} />
                <Featured />
                <Convinced sliderRef={sliderRef} handlePreviousSlide={handlePreviousSlide} handleNextSlide={handleNextSlide} />
                <section className='explore-decks' ref={deckRef}>
                    <h2 className='text-center text-black mb-3 mb-lg-4 deck-common-sub-heading'>Choose a Plan</h2>
                    <p className='text-black deck-common-body-md text-center text-capitalize'>Subscribe to a Plan and start building your portfolio</p>

                    <div className='container d-flex flex-column align-items-center justify-content-center'>
                        {decksQuery.isLoading ? (
                          <p className='fw-medium mt-3'>Loading</p>
                        ) : (
                          decksData.map((deck, index) => (
                            <ExploreDeckCard key={index} deck={deck} selectedTime={undefined} utmData={utmData} />
                          ))
                        )}
                    </div>
                </section>
                <FeatureSection />
                <Expert />

                <section className='works container'>
                    <h2 className='text-center deck-common-sub-heading'>How it works</h2>
                    <HowItWorks />
                </section>

                {/* Explore Decks Section */}



                <Subscribe />

                <FAQDeck />
                <DeckFooter />
            </Suspense>

            {decksQuery?.data?.length && (
                <div className='d-flex align-content-center justify-content-between d-md-none position-fixed bottom-0 w-100 offer-section overflow-hidden'>
                    <div>
                        <h3 className='title-offer'>Get Flat 10% OFF!</h3>
                        <p className='desc-offer'>
                            <span style={{ fontSize: '0.875rem' }}>
                                Use code SUPER10
                            </span>
                        </p>
                    </div>
                    <button onClick={navigateToPayment}>Explore Plans</button>
                </div>
            )}
        </div>
    );
};

export default TradingAlphas;
