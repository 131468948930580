import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '@/assets/images/logo.svg'
import Footer from './Footer';
import navStar from '@/assets/images/common/navStar.png'
import { getAppURL } from '@/utils/checkOS';

export interface B2CLayoutProps {
  children: React.ReactNode
}

export const B2CLayout = (props: B2CLayoutProps) => {

  let appURL = getAppURL();

  const { pathname: activePath } = useLocation();

  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top" style={{ backgroundColor: '#fff' }}>
        <div className="container">
          <a className="navbar-brand" href="/"><LogoSvg width={90} height={50} /></a>
          <div className='d-flex align-items-center'>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className={`nav-link ${activePath === '/' ? 'active' : null}`} href="/">Home</a>
              </li>
              <li className="nav-item">
                <div className='imgLink'>
                  <a className={`nav-link ${activePath === '/decks' ? 'active' : null}`} href="/decks">Premium</a>
                  <img src={navStar} alt="star" />
                </div>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activePath === '/freeTrial' ? 'active' : null}`} href="/freeTrial">Free Trial</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activePath === '/getverified' ? 'active' : null}`} href="/getverified">Get Verified</a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link btn-downloadApp" onClick={() => {
                  window.location.replace(appURL)
                }}>Download app</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {props.children}
      <Footer />
    </>
  );
}
