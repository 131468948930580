import React, {useEffect, useState} from 'react';
import WhatsappIcon from '@/assets/images/gift_cards/whatsappIcon.png';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import '../../../styles/rewardsMyGiftCards.scss';
import CopyClip from '@/assets/images/gift_cards/copy_clip.png';
import storage from "@/utils/storage";
import {getGiftPurchaseList} from "@/features/giftcard/api/redeemRewardsCard";
import {formatDate} from "@/utils/utility";
import ArrowLeft from "@/assets/images/gift_cards/arrow-left.png";
import NoBrand from "@/assets/images/gift_cards/no_brand.png";

const RewardsMyGiftCards = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [giftCardList, setGiftCardList] = useState<null | any>([]);
    const [copiedText, setCopiedText] = useState<any>('');
    const SecondDefaultImage = NoBrand;

    const fetchAPIData = async () => {
        const getQueryParam = (param:any) => {
            const searchParams = new URLSearchParams(location.search);
            return searchParams.get(param);
        };

        const token = getQueryParam('token');
        if(token && token !== ''){
            storage.setToken(token);
        }
        const res = await getGiftPurchaseList();
        setGiftCardList(res);
    };

    // const getQueryParamAfterPayment = (param:any) => {
    //     const searchParams = new URLSearchParams(location.search);
    //     return searchParams.get(param);
    // };
    //
    // let searchParams = getQueryParamAfterPayment('status');
    //
    // // useEffect(() => {
    // //     // Parse query parameters from the URL
    // //     const searchParams = new URLSearchParams(location.search);
    // //     const status = searchParams.get('status');
    // //
    // //     if (status === 'completed') {
    // //         alert('Payment successful!');
    // //
    // //         // Handle the back button navigation
    // //         const handleBackButton = (event : any) => {
    // //             event.preventDefault();
    // //             alert('You cannot go back after payment.');
    // //             // Optionally redirect or stay on this page
    // //             navigate('/redeem-rewards'); // Example redirection
    // //         };
    // //
    // //         // Adding an extra history state to prevent default back navigation
    // //         window.history.pushState(null, '', window.location.href);
    // //
    // //         // Add popstate event listener for handling back button
    // //         window.addEventListener('popstate', handleBackButton);
    // //
    // //         // Prevent page reloads or navigation away using beforeunload
    // //         const handleBeforeUnload = (event : any) => {
    // //             event.preventDefault();
    // //             event.returnValue = '';
    // //         };
    // //         window.addEventListener('beforeunload', handleBeforeUnload);
    // //
    // //         // Cleanup event listeners when component unmounts
    // //         return () => {
    // //             window.removeEventListener('popstate', handleBackButton);
    // //             window.removeEventListener('beforeunload', handleBeforeUnload);
    // //         };
    // //     }
    // // }, [location, navigate]);


    useEffect(() => {
        fetchAPIData();
    }, [params]);

    const copyToClipboard = (text:any) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setCopiedText(text);
                setTimeout(() => setCopiedText(''), 2000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleBack = () => {
        navigate('/redeem-rewards');
    };

    const onClickToOpenWhatsApp = () => {
        const phoneNumber = '+918451855382';
        const message = 'Hi there! I need help with gift cards.';

        const whatsappURLWeb = `https://wa.me/${phoneNumber.replace('+', '')}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURLWeb, '_blank');
    };

    return (
        <div className="rewards-my-gift-cards">
            <header>
                <img
                    src={ArrowLeft}
                    className="back-button"
                    onClick={handleBack}
                />
                <div className="card-header">My Gift Cards</div>
                <button className="support-button" onClick={onClickToOpenWhatsApp}>
                    <img src={WhatsappIcon} alt="WhatsappIcon" className="whatsapp-icon"/>
                    <p className="support-text">Support</p>
                </button>
            </header>
            <div className="gift-card-list">
                {giftCardList?.length > 0 && giftCardList.map((item:any) => (
                    <div className="my-gift-main-section">
                        <div key={item?.id} className="my-gift-card">
                            {
                                item?.logo ? (
                                    <img
                                        src={item?.logo}
                                        onError={(e:any) => {
                                            e.target.onerror = null;
                                            e.target.src = SecondDefaultImage;
                                        }}
                                        className="image-container"
                                    />
                                ) : (
                                    <img
                                        src={NoBrand}
                                        className="image-container"
                                    />
                                )
                            }
                            <div style={{flex: 1, paddingLeft: 12, overflow: "hidden"}}>
                                <div className="gift-card-header">
                                    <div className="store-name">
                                        <div>{item?.data?.name?.length > 8 ? item?.data?.name.substring(0, 8) + '...' : item?.data?.name}</div>
                                    </div>
                                    <div className="expiry-date">
                                        <div>Expires on {`${formatDate(item?.validity) || ''}`}</div>
                                    </div>
                                </div>
                                <div className="gift-card-details">
                                    <p>TXN : {item.transaction_id}</p>
                                    <div className="coupon-info">
                                            <div className="coupon-row" style={{marginTop: 7}}>
                                                <p>Card number :</p><p className="highlight">{item?.card_number || ''}</p>
                                                {item?.card_number ? (
                                                    <img src={CopyClip} alt={CopyClip} className="copy-icon" onClick={() => copyToClipboard(item?.card_number)}/>
                                                ) : null}
                                            </div>
                                        <div className="coupon-row">
                                            <p>PIN :</p><p className="highlight">{item?.card_pin || ''}</p>
                                            {item?.card_pin ? (
                                                <img src={CopyClip} alt={CopyClip} className="copy-icon" onClick={() => copyToClipboard(item?.card_pin)}/>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="purchase-amount-container">
                            <p>Purchased on {`${formatDate(item.issuance_date) || ''}`}</p>
                            <div className="gift-card-amount">{item?.currency?.symbol}{`${item?.amount}/-` || ''}</div>
                        </div>
                    </div>
                )) || (
                    <h6 style={{textAlign: 'center',}}>{"No gift cards purchased yet"}</h6>
                )}
            </div>

            {/* Popup notification */}
            {copiedText && (
                <div className="popup-notification">
                    Copied to clipboard: {copiedText}
                </div>
            )}
        </div>
    );
};

export default RewardsMyGiftCards;
